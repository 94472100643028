<template>
  <div class="table">
    <div class="container">

      <el-form
        ref="queryForm"
        :model="query"
        :inline="true"
        label-width="120px"
      >

        <el-form-item prop="userName">
          <el-input
            v-model="query.userName"
            placeholder="请输入姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="ip">
          <el-input
            v-model="query.ip"
            placeholder="ip"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">
            搜索
          </el-button>
          <el-button icon="el-icon-reset" @click="resetForm('queryForm')">
            重置
          </el-button>
        </el-form-item>
      </el-form>

      <!--数据表格-->
      <el-table
        v-loading="listLoading"
        :data="list"
        element-loading-text="正在加载 ..."
        border
        fit
        highlight-current-row
        @selection-change="handleSelectionChange"
        @row-dblclick="onEdit"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="操作人姓名" prop="userName" width="160"/>
        <el-table-column label="操作ip" prop="ip" width="120"/>
        <el-table-column label="操作时间" prop="createTime" width="160" />
        <el-table-column label="操作客户端" prop="type"  />
        <el-table-column label="访问接口" prop="content" width="260"/>


      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="pageInfo.startPage"
          :page-sizes="[10, 20, 30, 50, 100, 200]"
          :page-size="pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.total"
          @size-change="onSizeChange"
          @current-change="onPageChange"
        />
      </div>
    </div>

  </div>
</template>

<script>
import {
  getLogListWx
} from '@/api/admin/user';


export default {
  name: 'Log',
  data() {
    return {
      query: {
        userName: '',
        ip: ''
      },
      pageInfo: {
        startPage: 1,
        pageSize: 20,
        total: 0,
        ip: '',
        userName: ''
      },
      list: [],
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      multipleSelection: []
    };
  },
  created() {
    //初始化数据
    this.fetchData();
  },
  methods: {
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    search() {
      this.pageInfo.ip = this.query.ip;
      this.pageInfo.userName = this.query.userName;
      this.fetchData();
    },
    onRefresh() {
      this.fetchData();
    },
    fetchData() {
      this.listLoading = true;
      getLogListWx(this.pageInfo).then(response => {
        this.list = response.data.records;
        this.pageInfo.total = parseInt(response.data.total);
        this.pageInfo.startPage = parseInt(response.data.current);
        this.pageInfo.pageSize = parseInt(response.data.size);
        this.listLoading = false;
      });
    },
    submitForm(formName) {

    },
    save() {

    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
.upload-file {
  display: inline-block;
  margin-right: 10px;
}
.select-input {
  width: 350px !important;
}
</style>
